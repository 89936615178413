import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, SxProps, Typography } from '@mui/material';

const Copy = ({ text, sx }: { text: string; sx?: SxProps }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Box display="flex" gap="8px" sx={sx}>
      <Typography fontStyle="italic" fontWeight={600} fontSize="14px" style={{ wordBreak: 'break-all' }}>
        {text}
      </Typography>
      <ContentCopyIcon style={{ cursor: 'pointer' }} onClick={copyToClipboard} />
    </Box>
  );
};

export default Copy;

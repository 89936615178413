export const BKASH_B = 'bkash_b';
export const NAGAD_B = 'nagad_b';
export const UPAY = 'upay';
export const D17_L = 'd17_l';
export const PHONEPE = 'phonepe';
export const UPI_P2P = 'upi_p2p';
export const PAYTM = 'paytm';
export const UPI_FAST_V = 'upi_fast_v';
export const IMPS = 'imps';

export const PHONE_NUMBER_REGEXP = /^254[0-9]{9}$/;
export const EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const ACCOUNT_NUMBER_LENGTH = 11;
export const AMOUNT_FIELD_NAME = 'amount';

export const DEPOSIT_CONFIG = {
  [BKASH_B]: {
    [AMOUNT_FIELD_NAME]: {},
    account_number: {},
  },
  [NAGAD_B]: {
    [AMOUNT_FIELD_NAME]: {},
  },
  [UPAY]: {
    [AMOUNT_FIELD_NAME]: {},
  },
  [D17_L]: {
    [AMOUNT_FIELD_NAME]: {},
  },
  [PHONEPE]: {
    [AMOUNT_FIELD_NAME]: {},
  },
  [UPI_P2P]: {
    [AMOUNT_FIELD_NAME]: {},
  },
  [PAYTM]: {
    [AMOUNT_FIELD_NAME]: {},
  },
  [UPI_FAST_V]: {
    [AMOUNT_FIELD_NAME]: {},
  },
  [IMPS]: {
    [AMOUNT_FIELD_NAME]: {},
  },
};

export const WITHDRAW_CONFIG = {
  [BKASH_B]: {
    [AMOUNT_FIELD_NAME]: {},
    account_number: {
      length: ACCOUNT_NUMBER_LENGTH,
    },
    account_email: {
      regexp: EMAIL_REGEXP,
    },
  },
  [NAGAD_B]: {
    [AMOUNT_FIELD_NAME]: {},
    account_number: {},
  },
  [UPAY]: {
    [AMOUNT_FIELD_NAME]: {},
    account_number: {},
    account_email: {
      regexp: EMAIL_REGEXP,
    },
  },
  [D17_L]: {
    [AMOUNT_FIELD_NAME]: {},
    phone_number: {
      regexp: PHONE_NUMBER_REGEXP,
    },
  },
  [PHONEPE]: {
    [AMOUNT_FIELD_NAME]: {},
    account_number: {},
  },
  [UPI_P2P]: {
    [AMOUNT_FIELD_NAME]: {},
    account_number: {},
    account_email: {
      regexp: EMAIL_REGEXP,
    },
  },
  [PAYTM]: {
    [AMOUNT_FIELD_NAME]: {},
    account_number: {},
  },
  [UPI_FAST_V]: {
    [AMOUNT_FIELD_NAME]: {},
    account_number: {},
  },
  [IMPS]: {
    [AMOUNT_FIELD_NAME]: {},
    account_number: {},
    account_name: {},
    bank_code: {},
  },
};

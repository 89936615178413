import noop from 'lodash/noop';

import useSettings from 'store/settings';
import useSelectedTheme from 'store/theme';

const Logo = ({ width = 159, height = 43, onClick = noop, useInlineBounds = false, contains = false }) => {
  const [configs] = useSettings();
  const [{ isDark }] = useSelectedTheme();

  const logoImage = configs.data?.[isDark ? 'logoDark' : 'logo'];

  return logoImage ? (
    <img
      src={logoImage}
      alt="logo"
      style={{
        ...(useInlineBounds && { maxWidth: width, maxHeight: height }),
        ...(contains && { objectFit: 'contain' }),
      }}
      {...(!useInlineBounds && { width, height })}
      onClick={onClick}
    />
  ) : null;
};

export default Logo;

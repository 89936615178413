import { BrowserRouter as Router } from 'react-router-dom';

import AppLayout from 'pages/AppLayout';

import StoreProvider from 'store/StoreProvider';
import ThemeProvider from 'theme/ThemeProvider';
import ErrorHandler from 'errorHandling/ErrorHandler';
import ToasterProvider from 'components/common/Toaster';
import DatePickerProvider from 'components/common/Form/DatePicker/Provider';

import { TranslatesProvider } from 'utils/translate';
import { datadogRumInit } from 'utils/datadogRum';
import useMount from 'hooks/useMount';

import 'index.css';
import { accessStorage } from 'store/storage';

const searchParams = new URLSearchParams(window.location.search);
const token = searchParams.get('local_unliment_token');

if (token) {
  accessStorage.set(token);
  searchParams.delete('local_unliment_token');
  window.location.search = searchParams.toString();
}

const App = (): React.ReactElement => {
  useMount(() => {
    datadogRumInit();
  });

  return (
    <StoreProvider>
      <ThemeProvider>
        <TranslatesProvider>
          <ToasterProvider>
            <ErrorHandler>
              <DatePickerProvider>
                <Router>
                  <AppLayout />
                </Router>
              </DatePickerProvider>
            </ErrorHandler>
          </ToasterProvider>
        </TranslatesProvider>
      </ThemeProvider>
    </StoreProvider>
  );
};
export default App;

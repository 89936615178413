import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Drawer, ListItemText, Menu, MenuItem, Stack, Switch, Typography } from '@mui/material';

import BonusIcon from 'components/common/icons/Bonus';
import RealWalletIcon from 'components/common/icons/RealWallet';

import useBonusBalance from 'store/bonusBalance';
import useDialog from 'store/dialog';
import useHideBalance from 'store/hideBalance';
import useRealBalance from 'store/realBalance';

import { priceFormatter } from 'utils/price';
import useTranslates from 'utils/translate';
import useScreen from 'hooks/useScreen';

import useSettings, { IWebsiteConfigs } from 'store/settings';
import sx from './styles';
import useMount from 'hooks/useMount';

interface UserWalletProps {
  showOnlyBalance?: boolean;
}

const BlurredBalance = () => <span style={{ filter: 'blur(4px)' }}>00000000</span>;

const HIDE_ENABLE_PAYING_FROM_BONUS_WALLET = false;

const UserWallet: FC<UserWalletProps> = ({ showOnlyBalance }) => {
  const navigate = useNavigate();
  const { isMobile } = useScreen();
  const { translate } = useTranslates();
  const [, dialogActions] = useDialog();
  const [
    {
      data: { meta = {} as IWebsiteConfigs },
    },
  ] = useSettings();
  const [hideBalance, { toggle: hideBalanceToggle }] = useHideBalance();

  const [bonusBalanceState, bonusBalanceActions] = useBonusBalance();
  const [realBalanceState, realBalanceActions] = useRealBalance();

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const { amount: userBalance, withdrawableAmount } = realBalanceState;

  useMount(() => {
    bonusBalanceActions.read();
    realBalanceActions.read();
  });

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const content = (
    <Box>
      <Box sx={sx.menuHead}>
        <Box width="100%" display="flex" flexDirection="column" gap={1}>
          <ListItemText>
            <Typography fontSize={18} fontWeight={600} color="text.primary">
              {translate('my_wallet')}
            </Typography>
          </ListItemText>
          <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" gap={1} alignItems="center">
              <Switch checked={hideBalance} onClick={hideBalanceToggle} />
              {translate('hide_balance')}
            </Box>
            {HIDE_ENABLE_PAYING_FROM_BONUS_WALLET && (
              <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                <Switch
                  checked={realBalanceState.enablePayingFromBonusWallet}
                  onClick={() => realBalanceActions.enablePayingFromBonusWallet()}
                />
                {translate('enable_paying_from_bonus_wallet')}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box px={3} py={2} bgcolor="secondary.dark">
        <Box mt={1} display="flex" justifyContent="space-between">
          <Box
            sx={({ palette }) => ({ ...sx.menuInfo, fill: palette.text.primary })}
            gap={1}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Stack gap={1} direction="row">
              <RealWalletIcon sx={{ fontSize: 30 }} />
              <Box ml={1}>
                <Typography fontSize={12} fontWeight={600} sx={{ opacity: 0.5 }}>
                  {translate('wallet')}
                </Typography>
                <Typography fontSize={14} fontWeight={600}>
                  {hideBalance ? <BlurredBalance /> : priceFormatter(userBalance)}
                </Typography>
              </Box>
            </Stack>
            <Stack gap={1}>
              <Typography fontSize={12} fontWeight={600} sx={{ opacity: 0.5 }}>
                {translate('withdrawableAmount')}
              </Typography>
              <Typography fontSize={14} fontWeight={600}>
                {hideBalance ? <BlurredBalance /> : priceFormatter(withdrawableAmount)}
              </Typography>
            </Stack>
          </Box>
          <Stack direction="row" gap={1} sx={({ palette }) => ({ ...sx.menuInfo, fill: palette.primary.main })}>
            <BonusIcon sx={{ fontSize: 30 }} />
            <Box>
              <Typography fontSize={12} fontWeight={600} sx={{ opacity: 0.5 }}>
                {translate('bonus')}
              </Typography>
              <Typography fontSize={14} fontWeight={600} color="primary.main">
                {hideBalance ? <BlurredBalance /> : priceFormatter(bonusBalanceState.amount)}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
      {meta.showDeposit && (
        <MenuItem onClick={() => dialogActions.set('deposit')}>
          <ListItemText>{translate('deposit')}</ListItemText>
        </MenuItem>
      )}
      {meta.showWithdraw && (
        <MenuItem onClick={() => dialogActions.set('withdraw')}>
          <ListItemText>{translate('withdraw')}</ListItemText>
        </MenuItem>
      )}
      <MenuItem
        onClick={() => {
          navigate('wallet-history');
          handleClose();
        }}
      >
        <ListItemText>{translate('wallet_history')}</ListItemText>
      </MenuItem>
    </Box>
  );

  return (
    <>
      <Box sx={sx.root}>
        {showOnlyBalance ? (
          <Stack gap={1} direction="row" onClick={handleOpen}>
            <RealWalletIcon sx={{ fontSize: 14, mr: 1 }} />
            <Typography fontSize={10} fontWeight={600}>
              {hideBalance ? <BlurredBalance /> : priceFormatter(userBalance)}
            </Typography>
          </Stack>
        ) : (
          <>
            {meta.showDeposit && (
              <Button variant="contained" color="success" sx={sx.button} onClick={() => dialogActions.set('deposit')}>
                {translate('deposit_now')}
              </Button>
            )}
            <Box sx={{ ml: 3, cursor: 'pointer' }} onClick={handleOpen}>
              <Stack gap={1} direction="row" sx={({ palette }) => ({ ...sx.item, fill: palette.text.primary })}>
                <Typography fontSize={14} fontWeight={600} mr={1}>
                  {hideBalance ? <BlurredBalance /> : priceFormatter(userBalance)}
                </Typography>
                <RealWalletIcon sx={{ fontSize: 14 }} />
              </Stack>
              <Stack gap={1} direction="row" sx={({ palette }) => ({ ...sx.item, fill: palette.primary.main })}>
                <Typography fontSize={12} fontWeight={600} color="primary.main">
                  {hideBalance ? <BlurredBalance /> : priceFormatter(bonusBalanceState.amount)}
                </Typography>
                <BonusIcon sx={{ fontSize: 14 }} />
              </Stack>
            </Box>
          </>
        )}
      </Box>
      {isMobile ? (
        <Drawer open={open} anchor="top" onClose={handleClose} sx={{ zIndex: 1300 }} PaperProps={sx.menuList}>
          {content}
        </Drawer>
      ) : (
        <Menu
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          PaperProps={sx.menuPaper}
          MenuListProps={sx.menuList}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {content}
        </Menu>
      )}
    </>
  );
};

export default UserWallet;

const TwoFactorVerification = () => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.54128 3.63374C8.16393 2.57131 10.0605 2.00369 12 2C13.9396 2.00369 15.8362 2.57131 17.4588 3.63374C19.0815 4.69617 20.3602 6.20754 21.1391 7.98377C21.6433 9.13337 21.924 10.3618 21.9732 11.6042L19.7937 13.7863C19.9295 13.2031 19.9993 12.6039 20 12C20 9.87827 19.1572 7.84344 17.6569 6.34315C16.1566 4.84285 14.1218 4 12 4C9.87832 4 7.84349 4.84285 6.34319 6.34315C4.8429 7.84344 4.00005 9.87827 4.00005 12C4.00177 13.4065 4.37799 14.7871 5.09005 16C5.98352 14.4718 7.35865 13.283 9.00005 12.62C8.49088 12.0427 8.15912 11.3307 8.04458 10.5694C7.93004 9.80822 8.03758 9.03011 8.3543 8.3285C8.67102 7.62688 9.18346 7.03156 9.83014 6.61397C10.4768 6.19637 11.2303 5.97425 12 5.97425C12.7698 5.97425 13.5233 6.19637 14.17 6.61397C14.8166 7.03156 15.3291 7.62688 15.6458 8.3285C15.9625 9.03011 16.0701 9.80822 15.9555 10.5694C15.841 11.3307 15.5092 12.0427 15 12.62C16.344 13.1629 17.5094 14.0581 18.3781 15.2037L16.9478 16.6357C16.5198 16.0119 15.9755 15.4714 15.3408 15.0459C14.3526 14.3835 13.1897 14.0298 12 14.0298C10.8104 14.0298 9.64752 14.3835 8.6593 15.0459C7.67108 15.7083 6.90209 16.6495 6.45005 17.75C7.83509 19.0898 9.65334 19.8812 11.5678 19.9877L13.4495 21.8737C12.9707 21.9439 12.4863 21.9793 12 21.9793C10.619 21.9793 9.25298 21.6933 7.98799 21.1392C6.72299 20.5851 5.58647 19.775 4.65005 18.76C3.33893 17.3308 2.47173 15.5511 2.15408 13.6378C1.83643 11.7244 2.08204 9.76 2.86099 7.98377C3.63994 6.20754 4.91862 4.69617 6.54128 3.63374ZM10.3371 8.88886C10.1173 9.21776 10 9.60444 10 10C10 10.5304 10.2108 11.0391 10.5858 11.4142C10.9609 11.7893 11.4696 12 12 12C12.3956 12 12.7823 11.8827 13.1112 11.6629C13.4401 11.4432 13.6964 11.1308 13.8478 10.7654C13.9992 10.3999 14.0388 9.99778 13.9616 9.60982C13.8844 9.22186 13.694 8.86549 13.4143 8.58579C13.1346 8.30608 12.7782 8.1156 12.3902 8.03843C12.0023 7.96126 11.6001 8.00087 11.2347 8.15224C10.8692 8.30362 10.5569 8.55996 10.3371 8.88886Z"
      fill="#36C3FF"
    />
  </svg>
);

export default TwoFactorVerification;

import { useCallback, useEffect, useRef } from 'react';
import { sendEventAdForm } from './adForm';
import { AnalyticsKey, AnalyticsPageViewEvents } from './enum';
import useGlobalSettings from 'store/globalSettings';
import { IGlobalSettingsMeta } from 'api/settings';

const useAnalytics = () => {
  const [
    {
      status,
      data: { meta = {} as IGlobalSettingsMeta },
    },
  ] = useGlobalSettings();

  const queue = useRef([]);

  const sendEvent = useCallback(
    async (params: Partial<Record<AnalyticsKey, string | AnalyticsPageViewEvents>>) => {
      if (status !== 'success') {
        queue.current.push(params);
        return;
      }
      if (meta.adFormAnalyticsKey) {
        sendEventAdForm({
          analyticKey: meta.adFormAnalyticsKey,
          params,
        });
      }
    },
    [meta.adFormAnalyticsKey]
  );

  useEffect(() => {
    if (status === 'success' && queue.current.length) {
      queue.current.forEach(sendEvent);
      queue.current = [];
    }
  }, [status]);

  return { sendEvent };
};

export default useAnalytics;

export interface ICountry {
  id: number;
  name: string;
  dial_code: string;
  code: string;
  flag: string;
  ioc: string;
  currencies?: string[];
  emoji?: string;
  alpha2?: string;
  alpha3?: string;
}

export type Countries = Record<string, ICountry>;

export interface ICurrency {
  id: number;
  websiteId: number;
  currency_id: number;
  order_id: number;
  description: string;
  code: string;
}

export interface IGlobalSettingsMeta {
  zendeskKey?: string;
  bonusPlayerRewardClaimable: boolean;
  adFormAnalyticsKey?: string;
}

export enum TwoFactorAuthenticationState {
  'off' = 'off',
  'optional' = 'optional',
  'mandatory' = 'mandatory',
  'encouraged' = 'encouraged',
}

export interface IGlobalSettings {
  currencies: ICurrency[];
  dateFormat: string;
  meta: IGlobalSettingsMeta;
  twoFactorState: TwoFactorAuthenticationState;
  languages: {
    id: number;
    flag: string;
    iso_1: string;
    iso_2: string;
    name: string;
    native: string;
  }[];
  playerMinAge: string;
  mailingApiKey: string;
  mailingFromEmail: string;
}

export interface ICategory {
  id: number;
  icon: string;
  icon_dark: string;
  key: string;
  uri: string;
  name: string;
}

export interface ILanguage {
  id: number;
  websiteId: number;
  langId: number;
  is_default: boolean;
  isDefault: boolean;
  code: string;
  iso_1: string;
  dial_code: string;
  flag: string;
  name: string;
  native: string;
}

export interface IGameTag {
  id: number;
  icon: string;
  tag: string;
  icon_dark: string;
}
